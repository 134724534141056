let purpleTheme = {
    BASE: {
        'color': '#3f3f3f',
        'line-height': '1.75',
        'letter-spacing': '1px',
        'margin': '0 8px',
        'font-family': '-apple-system-font,BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB , Microsoft YaHei UI , Microsoft YaHei ,Arial,sans-serif'
    },
    block: {
        h1: {
            'font-size': '22px',
            'color': 'purple'
        },
        h2: {
            'font-size': '20px',
            'color': 'purple'
        },
        h3: {
            'font-size': '18px',
            'color': 'purple'
        },
        h4: {
            'font-size': '16px',
            'color': 'purple'
        },
        p: {},
        blockquote: {
            'font-style': 'normal',
            'padding': '0.5em',
            'border-radius': '0 4px 4px 0',
            'background': 'rgba(27,31,35,.05)',
            'border-left': '3px solid rgb(158,158,158,0.6)',
        },
        blockquote_p: {
            'line-height': '1.5',
            'letter-spacing': '1px',
            'color': 'rgb(80, 80, 80)',
            'font-size': '14px',
            'display': 'inline',
        },
        code: {
        },
        image: {
            'display': 'block',
            'margin': '0 auto',
            'width': '75%'
        },
        ol: {},
        ul: {
            'list-style': 'circle'
        },
        footnotes: {
            'font-size': '80%'
        },
        figure: {}
    },
    inline: {
        listitem: {
            'display': 'block',
        },
        codespan: {
            'font-size': '90%',
            'color': '#595959',
            'background': 'rgba(27,31,35,.05)',
            'padding': '2px 4px',
            'border-radius': '3px',
        },
        link: {
            'color': '#576b95',
            'margin': '0'
        },
        wx_link: {
            'color': '#576b95',
            'text-decoration': 'none',
            'margin': '0',
            'border-bottom': '1px solid #576b9580'
        },
        strong: {
            'margin': '0'
        },
        em: {
            'margin': '0'
        },
        table: {
            'border-collapse': 'collapse',
            'display': 'block',
        },
        thead: {
            'background': 'rgba(0, 0, 0, 0.05)'
        },
        td: {
            'font-size': '80%',
            'border': '1px solid #dfdfdf',
            'padding': '0.25em 0.5em'
        },
        figcaption: {
            'text-align': 'center',
            'color': '#888',
            'font-size': '12px'
        }
    }
};
export default purpleTheme
