import Vue from 'vue'
import VueCodemirror from 'vue-codemirror'

import 'codemirror/lib/codemirror.css'

import './code-mirror-light.css'
import './code-mirror-dark.css'


Vue.use(VueCodemirror)
