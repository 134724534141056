import marked from 'marked'

let WxRenderer = function (opts) {
    this.opts = opts;
    let ENV_USE_REFERENCES = true;
    let isEmptyLine = true;
    let showCodeLineNum = false;

    let imgShadow = '';
    let imgCorner = '';

    let footnotes = [];
    let footnoteIndex = 0;
    let styleMapping = null;

    let CODE_FONT_FAMILY = "Menlo, Operator Mono, Consolas, Monaco, monospace";

    let merge = function (base, extend) {
        return Object.assign({}, base, extend)
    };

    this.buildTheme = function (themeTpl) {
        let mapping = {};

        let base = merge(themeTpl.BASE, {
            'font-size': this.opts.size,
            'letter-spacing': this.opts.letterSpacing,
            'line-height': this.opts.lineSpacing,
            'text-align': this.opts.justifyAlign
        });

        if (this.opts.paragraphSpacing === '-1') {
            isEmptyLine = true
        } else {
            isEmptyLine = false
            base = merge(base, {
                'margin-bottom': this.opts.paragraphSpacing,
            })
        }

        showCodeLineNum = this.opts.showCodeLineNum
        imgShadow = this.opts.imgShadow
        imgCorner = this.opts.imgCorner

        for (let ele in themeTpl.inline) {
            if (themeTpl.inline.hasOwnProperty(ele)) {
                let style = themeTpl.inline[ele];
                if (ele === 'codespan') {
                    style['font-family'] = CODE_FONT_FAMILY;
                    style['white-space'] = 'normal';
                }
                mapping[ele] = merge(base, style)
            }
        }

        for (let ele in themeTpl.block) {
            if (themeTpl.block.hasOwnProperty(ele)) {
                let style = themeTpl.block[ele];
                if (ele === 'code') {
                    style['font-family'] = CODE_FONT_FAMILY
                }
                mapping[ele] = merge(base, style)
            }
        }

        return mapping
    };

    let getStyles = function (tokenName, addition) {
        let arr = [];
        let dict = styleMapping[tokenName];
        if (!dict) return '';
        //code有自己的样式，公共样式只用margin-bottom
        if (tokenName === 'code') {
            return `style="${'margin-bottom' + ':' + dict['margin-bottom']}"`
        }
        for (const key in dict) {
            arr.push(key + ':' + dict[key])
        }
        return `style="${arr.join(';') + (addition || '')}"`
    };

    let addFootnote = function (title, link) {
        footnoteIndex += 1;
        footnotes.push([footnoteIndex, title, link]);
        return footnoteIndex
    };

    this.buildFootnotes = function () {
        let footnoteArray = footnotes.map(function (x) {
            if (x[1] === x[2]) {
                return `<code style="font-size: 90%; opacity: 0.6;">[${x[0]}]</code>: <i>${x[1]}</i><br/>`
            }
            return `<code style="font-size: 90%; opacity: 0.6;">[${x[0]}]</code> ${x[1]}: <i>${x[2]}</i><br/>`
        });
        return `<h3 ${getStyles('h3')}>相关链接</h3>` + (isEmptyLine ? '<br>' : '') + `<p ${getStyles('footnotes', ';word-break:break-all')}>${footnoteArray.join('\n')}</p>` + (isEmptyLine ? '<br>' : '')
    };

    this.buildAddition = function () {
        return '<style>.preview-wrapper pre::before{' +
            'font-family:"SourceSansPro","HelveticaNeue",Arial,sans-serif;' +
            'position:absolute;' +
            'top:0;' +
            'right:0;' +
            'color:#ccc;' +
            'text-align:right;' +
            'font-size:0.8em;' +
            'padding:5px10px0;' +
            'line-height:15px;' +
            'height:15px;' +
            'font-weight:600;' +
            '}</style>'
    };

    this.setOptions = function (newOpts) {
        this.opts = merge(this.opts, newOpts)
    };

    this.hasFootnotes = function () {
        return footnotes.length !== 0
    };

    this.getRenderer = function () {
        footnotes = [];
        footnoteIndex = 0;

        styleMapping = this.buildTheme(this.opts.theme);
        let renderer = new marked.Renderer();

        renderer.heading = function (text, level) {
            switch (level) {
                case 1:
                    return `<h1 ${getStyles('h1')}>${text}</h1>` + (isEmptyLine ? '<br>' : '');
                case 2:
                    return `<h2 ${getStyles('h2')}>${text}</h2>` + (isEmptyLine ? '<br>' : '');
                case 3:
                    return `<h3 ${getStyles('h3')}>${text}</h3>` + (isEmptyLine ? '<br>' : '');
                default:
                    return `<h4 ${getStyles('h4')}>${text}</h4>` + (isEmptyLine ? '<br>' : '');
            }
        };
        renderer.paragraph = function (text) {
            //marked把image和text都解析为了paragraph，这里我们不需要image是p，所以分别处理。如果是figure，就直接返回，不套p
            if (text.startsWith('<figure')) {
                return text
            }
            return `<p ${getStyles('p', ';word-break:break-all')}>${text}</p>` + (isEmptyLine ? '<br>' : '')
        };
        renderer.blockquote = function (text) {
            text = text.replace(/<p.*?>/, `<p ${getStyles('blockquote_p')}>`);
            return `<blockquote ${getStyles('blockquote')}>${text}</blockquote>` + (isEmptyLine ? '<br>' : '')
        };
        renderer.code = function (text, infoString) {
            text = text.replace(/</g, "&lt;");
            text = text.replace(/>/g, "&gt;");

            let lines = text.split('\n');
            let codeLines = [];
            let numbers = [];
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                codeLines.push(`<code class="prettyprint"><span>${(line || '<br>')}</span></code>`);
                numbers.push('<li></li>')
            }
            let lang = infoString || '';
            let result;

            // code-snippet__js这个样式不知道是哪个库中的
            // 加上code-snippet__js，就都有行号，不管复制不复制行号。能用官方高亮
            // 不加code-snippet__js，就都没有行号，不管复制不复制行号。不能用官方高亮
            // 所以在showCodeLineNum变量添加ul-num的基础上(给用户分辨)
            // 还要根据showCodeLineNum变量加载code-snippet__js样式（给公众号编辑器分辨）
            //
            // 猜测code-snippet__js可能是给公众号编辑器识别代码用的，并且如果点击，会变为官方的高亮样式

            if (showCodeLineNum) {
                result = `<section class="code-snippet__fix" ${getStyles('code')}>
                          <ul class="code-snippet__line-index code-snippet__js">${numbers.join('')}</ul>
                          <pre class="code-snippet__js" data-lang="${lang}">${codeLines.join('')}</pre></section>` + (isEmptyLine ? '<br>' : '')
            } else {
                result = `<section class="code-snippet__fix" ${getStyles('code')}>
                          <pre data-lang="${lang}">${codeLines.join('')}</pre></section>` + (isEmptyLine ? '<br>' : '')
            }
            return result
        };
        renderer.codespan = function (text, infoString) {
            return `<code ${getStyles('codespan')}>${text}</code>`
        };
        renderer.listitem = function (text) {
            return `<span ${getStyles('listitem', ';margin-bottom:0;word-break:break-all')}><span style="margin-right: 8px;"><%s/></span>${text}</span>`;
        };
        renderer.list = function (text, ordered, start) {
            text = text.replace(/<\/*p.*?>/g, '');
            let segments = text.split(`<%s/>`);
            if (!ordered) {
                text = segments.join('•');
                return `<p ${getStyles('ul')}>${text}</p>` + (isEmptyLine ? '<br>' : '');
            }
            text = segments[0];
            for (let i = 1; i < segments.length; i++) {
                text = text + i + '.' + segments[i];
            }
            return `<p ${getStyles('ol')}>${text}</p>` + (isEmptyLine ? '<br>' : '');
        };
        renderer.image = function (href, title, text) {
            let subText = '';
            if (text) {
                subText = `<figcaption ${getStyles('figcaption', ';margin-top:8px')}>${text}</figcaption>`
            }
            let figureStyles = getStyles('figure');
            let imgStyles = getStyles('image', ';margin-bottom:0;border-radius:' + imgCorner + ';box-shadow: 0 0' + ' ' + imgShadow + ' ' + 'rgba(0,0,0,0.5)');
            return `<figure ${figureStyles}><img ${imgStyles} src="${href}" title="${title}" alt="${text}"/>${subText}</figure>` + (isEmptyLine ? '<br>' : '')
        };
        renderer.link = function (href, title, text) {
            if (href.indexOf('https://mp.weixin.qq.com') === 0) {
                return `<a href="${href}" title="${(title || text)}" ${getStyles('wx_link')}>${text}</a>`;
            } else if (href === text) {
                return text;
            } else {
                if (ENV_USE_REFERENCES) {
                    let ref = addFootnote(title || text, href);
                    return `<span ${getStyles('link')}>${text}<sup>[${ref}]</sup></span>`;
                } else {
                    return `<a href="${href}" title="${(title || text)}" ${getStyles('link')}>${text}</a>`;
                }
            }
        };
        renderer.strong = function (text) {
            return `<b ${getStyles('strong')}>${text}</b>`;
        };
        renderer.em = function (text) {
            return `<i ${getStyles('em')}>${text}</i>`
        };
        renderer.table = function (header, body) {
            return `<table ${getStyles('table')}><thead ${getStyles('thead')}>${header}</thead><tbody>${body}</tbody></table>` + (isEmptyLine ? '<br>' : '');
        };
        renderer.tablecell = function (text, flags) {
            return `<td ${getStyles('td')}>${text}</td>`;
        };
        renderer.hr = function () {
            return `<hr style="border-style: solid;border-width: 1px 0 0;border-color: rgba(0,0,0,0.3);-webkit-transform-origin: 0 0;-webkit-transform: scale(1, 0.5);transform-origin: 0 0;transform: scale(1, 0.5);">`;
        };
        return renderer
    }
};
export default WxRenderer
