<template>
  <div id="app" class="container">
    <el-container>
      <el-header class="header" height="48px">
        <div style="display: flex;align-items: center">
          <img src="/images/logo.png" width="36" height="36">
          <div style="color: purple;font-style: italic;font-size: 24px;margin-left: 12px" class="font-song">Foxmd</div>
        </div>

        <div style="flex:1;padding-left: 108px;" class="hidden-sm-and-down">
          <input id="selectMD" type="file" @change="onFileSelected" accept=".md" style="display: none">
          <el-button type="text" icon="el-icon-notebook-2" @click="selectFile">
            导入文件
          </el-button>
        </div>

        <div class="font-kai hidden-sm-and-down" style="color: #888888">markdown 微信公众号编辑器</div>
        <div class="hidden-md-and-up" style="color: #888888;font-size: 12px;margin-left: 24px">请在电脑端使用完整功能</div>
      </el-header>
      <el-main class="main-body">
        <el-row :gutter="2" class="main-section">
          <!--elementui的bug，不能为0，所以最小设为了1，但是用hidde-sm隐藏它们-->
          <el-col :xs="1" :sm="1" :md="11" :lg="12" :xl="12" class="hidden-sm-and-down" style="position: relative">
                    <textarea
                        id="editor"
                        type="textarea"
                        v-model="source"></textarea>

            <div style="position: absolute;top: 1px;right: 1px;z-index: 2">
              <el-select style="width:72px" v-model="currentEditorTheme" size="mini" @change="editorThemeChanged">
                <el-option v-for="editorTheme in editorThemes" :key="editorTheme.value"
                           :label="editorTheme.label"
                           :value="editorTheme.value">
                </el-option>
              </el-select>
            </div>

          </el-col>
          <el-col :xs="24" :sm="24" :md="10" :lg="9" :xl="9" class="preview-wrapper" id="preview">
            <section>
              <div class="preview" contenteditable="false">
                <div id="output" v-html="output">
                </div>
              </div>
            </section>
          </el-col>
          <el-col :xs="1" :sm="1" :md="3" :lg="3" :xl="3" class="hidden-sm-and-down" style="position: relative">
            <div class="menu">
              <el-button @click="copy" type="primary" plain size="mini">一键复制</el-button>
              <el-divider><i class="el-icon-mobile-phone" style="color: #888888"></i></el-divider>

              <el-tooltip content="主题色" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_theme.png" class="menu-icon">
                  <el-select style="width:82px" v-model="currentTheme" size="mini" @change="themeChanged">
                    <el-option v-for="theme in themeOption" :key="theme.value" :label="theme.label"
                               :value="theme.value" :style="{color:theme.color}">
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>

              <el-tooltip content="文字大小" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_fontsize.png" class="menu-icon">
                  <el-select style="width:82px" v-model="currentSize" size="mini" @change="sizeChanged">
                    <el-option v-for="size in sizeOption"
                               :key="size.value"
                               :label="size.label"
                               :value="size.value">
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>

              <el-tooltip content="字间距" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_letter_spacing.png" class="menu-icon">
                  <el-select style="width:82px" v-model="currentLetterSpacing" size="mini"
                             @change="letterSpacingChanged">
                    <el-option v-for="letterSpacing in letterSpacingOption"
                               :key="letterSpacing.value"
                               :label="letterSpacing.label"
                               :value="letterSpacing.value">
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>

              <el-tooltip content="行间距" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_line_spacing.png" class="menu-icon">
                  <el-select style="width:82px" v-model="currentLineSpacing" size="mini" @change="lineSpacingChanged">
                    <el-option v-for="lineSpacing in lineSpacingOption"
                               :key="lineSpacing.value"
                               :label="lineSpacing.label"
                               :value="lineSpacing.value">
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>

              <el-tooltip content="段后间距" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_paragraph_spacing.png" class="menu-icon">
                  <el-select style="width:82px" v-model="currentParagraphSpacing" size="mini"
                             @change="paragraphSpacingChanged">
                    <el-option v-for="paragraphSpacing in paragraphSpacingOption"
                               :key="paragraphSpacing.value"
                               :label="paragraphSpacing.label"
                               :value="paragraphSpacing.value">
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>

              <el-tooltip content="两端对齐" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_justify_align.png" class="menu-icon">
                  <el-switch
                      @width="48"
                      @change="justifyChanged"
                      v-model="justifyAlign">
                  </el-switch>
                </div>
              </el-tooltip>

              <el-divider><i class="el-icon-picture-outline" style="color: #888888"></i></el-divider>

              <el-tooltip content="图片圆角" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_img_corner.png" class="menu-icon">
                  <el-select style="width:82px" v-model="currentImgCorner" size="mini"
                             @change="imgCornerChanged">
                    <el-option v-for="imgCorner in imgCornerOption"
                               :key="imgCorner.value"
                               :label="imgCorner.label"
                               :value="imgCorner.value">
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>

              <el-tooltip content="图片阴影" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_img_shadow.png" class="menu-icon">
                  <el-select style="width:82px" v-model="currentImgShadow" size="mini"
                             @change="imgShadowChanged">
                    <el-option v-for="imgShadow in imgShadowOption"
                               :key="imgShadow.value"
                               :label="imgShadow.label"
                               :value="imgShadow.value">
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>

              <el-divider><i class="el-icon-connection" style="color: #888888"></i></el-divider>

              <el-tooltip content="代码行号" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_code_line_num.png" class="menu-icon">
                  <el-switch
                      @width="48"
                      @change="showCodeLineNumChanged"
                      v-model="showCodeLineNum">
                  </el-switch>
                </div>
              </el-tooltip>

              <el-divider><i class="el-icon-magic-stick" style="color: #888888"></i></el-divider>

              <el-tooltip content="中英文之间插入空格" placement="left">
                <div class="menu-item">
                  <img src="/images/ic_insert_space.png" class="menu-icon">
                  <el-switch
                      @width="48"
                      @change="autoInsertSpaceChanged"
                      v-model="autoInsertSpace">
                  </el-switch>
                </div>
              </el-tooltip>
            </div>

            <div style="position: absolute;bottom: 0;left: 0;right: 0;text-align: center">
              <div>
                <el-button icon="el-icon-message" type="text" size="mini" @click="aboutDialogVisible = true">
                  联系我们
                </el-button>
              </div>

              <a href="http://beian.miit.gov.cn/" target="_blank" class="beian">冀ICP备16021363号-7</a>
            </div>
          </el-col>

        </el-row>
      </el-main>
    </el-container>

    <el-dialog title="联系我们" :visible.sync="aboutDialogVisible" width="480px" custom-class="dialog-contact" center>
      <div style="display: flex;align-items: baseline">
        反馈建议、交流合作请发送邮件到：&nbsp;&nbsp;<el-link href="mailto:foxmd@foxmail.com" target="_blank">foxmd@foxmail.com</el-link>&nbsp;&nbsp;。
      </div>
    </el-dialog>
  </div>
</template>

<script>
import briefTheme from "@/assets/scripts/themes/brief";
import greenTheme from "@/assets/scripts/themes/green";
import purpleTheme from "@/assets/scripts/themes/purple";
import orangeTheme from "@/assets/scripts/themes/orange";
import WxRenderer from "@/assets/scripts/wx-renderer";

import axios from 'axios'

import marked from 'marked'

import CodeMirror from 'codemirror'
import 'codemirror/mode/markdown/markdown'

import '@/assets/libs/prettify/prettify'
import '@/assets/libs/prettify/prettify.css'

import '@/assets/scripts/sync-scroll'

export default {
  name: 'App',
  data: function () {
    let d = {
      aboutOutput: '',
      output: '',
      source: '',
      editorThemes: [
        {label: 'light', value: 'thoth-light'},
        {label: 'dark', value: 'thoth-dark'},
      ],
      editor: null,
      sizeOption: [
        {label: '14px', value: '14px'},
        {label: '15px', value: '15px'},
        {label: '16px', value: '16px'},
      ],
      letterSpacingOption: [
        {label: '0.5px', value: '0.5px'},
        {label: '1px', value: '1px'},
        {label: '1.5px', value: '1.5px'},
      ],
      lineSpacingOption: [
        {label: '1.5倍', value: '1.5'},
        {label: '1.75倍', value: '1.75'},
        {label: '2倍', value: '2'},
      ],
      paragraphSpacingOption: [
        {label: '空行', value: '-1'},
        {label: '10px', value: '10px'},
        {label: '15px', value: '15px'},
        {label: '20px', value: '20px'},
      ],
      imgCornerOption: [
        {label: '无', value: '0'},
        {label: '2px', value: '2px'},
        {label: '4px', value: '4px'},
        {label: '6px', value: '6px'},
        {label: '8px', value: '8px'},
        {label: '10px', value: '10px'},
      ],
      imgShadowOption: [
        {label: '无', value: '0'},
        {label: '2px', value: '2px'},
        {label: '4px', value: '4px'},
        {label: '6px', value: '6px'},
        {label: '8px', value: '8px'},
        {label: '10px', value: '10px'},
      ],
      themeOption: [
        {label: '简约黑', value: 'brief', color: '#606266'},
        {label: '缤纷绿', value: 'green', color: 'green'},
        {label: '基佬紫', value: 'purple', color: 'purple'},
        {label: '活力橙', value: 'orange', color: 'orange'},
      ],
      styleThemes: {
        brief: briefTheme,
        green: greenTheme,
        purple: purpleTheme,
        orange: orangeTheme,
      },
      aboutDialogVisible: false
    };
    d.currentEditorTheme = d.editorThemes[0].value;

    d.currentSize = d.sizeOption[1].value;
    d.currentLetterSpacing = d.letterSpacingOption[1].value;
    d.currentLineSpacing = d.lineSpacingOption[1].value;
    d.currentParagraphSpacing = d.paragraphSpacingOption[0].value;
    d.currentImgCorner = d.imgCornerOption[0].value;
    d.currentImgShadow = d.imgShadowOption[0].value;
    d.currentTheme = d.themeOption[0].value;
    d.justifyAlign = true

    d.showCodeLineNum = false

    d.autoInsertSpace = false //考虑到性能，默认false

    return d;
  },
  mounted() {
    if (navigator.userAgent.indexOf("Chrome") < 0) {
      this.$message('建议使用Chrome浏览器以获取最佳使用体验');
    }

    this.checkCache(this.editorThemes, "__config_editor_theme")
    this.checkCache(this.sizeOption, "__config_size")
    this.checkCache(this.letterSpacingOption, "__config_letter_spacing")
    this.checkCache(this.lineSpacingOption, "__config_line_spacing")
    this.checkCache(this.paragraphSpacingOption, "__config_paragraph_spacing")
    this.checkCache(this.imgCornerOption, "__config_img_corder")
    this.checkCache(this.imgShadowOption, "__config_img_shadow")
    this.checkCache(this.themeOption, "__config_theme")


    let self = this;
    if (localStorage.getItem("__config_editor_theme")) {
      self.currentEditorTheme = localStorage.getItem("__config_editor_theme")
    }
    this.editor = CodeMirror.fromTextArea(
        document.getElementById('editor'),
        {
          lineNumbers: false,
          lineWrapping: true,
          styleActiveLine: true,
          theme: this.currentEditorTheme,
          mode: 'text/x-markdown',
        }
    );
    this.editor.on("change", function () {
      self.refresh();
      self.saveEditorContent();
    });
    if (localStorage.getItem("__config_size")) {
      self.currentSize = localStorage.getItem("__config_size")
    }
    if (localStorage.getItem("__config_letter_spacing")) {
      self.currentLetterSpacing = localStorage.getItem("__config_letter_spacing")
    }
    if (localStorage.getItem("__config_line_spacing")) {
      self.currentLineSpacing = localStorage.getItem("__config_line_spacing")
    }
    if (localStorage.getItem("__config_paragraph_spacing")) {
      self.currentParagraphSpacing = localStorage.getItem("__config_paragraph_spacing")
    }
    if (localStorage.getItem("__config_img_shadow")) {
      self.currentImgShadow = localStorage.getItem("__config_img_shadow")
    }
    if (localStorage.getItem("__config_img_corner")) {
      self.currentImgCorner = localStorage.getItem("__config_img_corner")
    }
    if (localStorage.getItem("__config_theme")) {
      self.currentTheme = localStorage.getItem("__config_theme")
    }
    if (localStorage.getItem("__config_justify")) {
      self.justifyAlign = localStorage.getItem("__config_justify") === 'true'
    }
    if (localStorage.getItem("__config_show_code_line_num")) {
      self.showCodeLineNum = localStorage.getItem("__config_show_code_line_num") === 'true'
    }
    if (localStorage.getItem("__config_auto_insert_space")) {
      self.autoInsertSpace = localStorage.getItem("__config_auto_insert_space") === 'true'
    }
    this.wxRenderer = new WxRenderer({
      size: this.currentSize,
      letterSpacing: this.currentLetterSpacing,
      lineSpacing: this.currentLineSpacing,
      paragraphSpacing: this.currentParagraphSpacing,
      imgShadow: this.currentImgShadow,
      imgCorner: this.currentImgCorner,
      theme: this.styleThemes[this.currentTheme],
      justifyAlign: this.justifyAlign ? 'justify' : 'left',
      showCodeLineNum: this.showCodeLineNum
    });

    // 如果有编辑内容被保存则读取，否则加载默认文档
    if (localStorage.getItem("__editor_content")) {
      this.editor.setValue(localStorage.getItem("__editor_content"));
    } else {
      axios({
        method: 'get',
        url: '/default-content.md',
      }).then(function (resp) {
        self.editor.setValue(resp.data)
      })
    }
  },
  methods: {
    renderWeChat: function (source) {
      let output = marked(source, {renderer: this.wxRenderer.getRenderer()});
      if (this.wxRenderer.hasFootnotes()) {
        // 去除第一行的 margin-top
        output = output.replace(/(style=".*?)"/, '$1;margin-top: 0"');
        // 引用注脚
        output += this.wxRenderer.buildFootnotes();
        // 附加的一些 style
        output += this.wxRenderer.buildAddition();
      }
      return output
    },
    editorThemeChanged: function (editorTheme) {
      this.editor.setOption('theme', editorTheme)
      localStorage.setItem("__config_editor_theme", editorTheme);
    },
    sizeChanged: function (size) {
      this.wxRenderer.setOptions({
        size: size
      });
      this.refresh()
      localStorage.setItem("__config_size", size);
    },
    letterSpacingChanged: function (letterSpacing) {
      this.wxRenderer.setOptions({
        letterSpacing: letterSpacing
      });
      this.refresh()
      localStorage.setItem("__config_letter_spacing", letterSpacing);
    },
    lineSpacingChanged: function (lineSpacing) {
      this.wxRenderer.setOptions({
        lineSpacing: lineSpacing
      });
      this.refresh()
      localStorage.setItem("__config_line_spacing", lineSpacing);
    },
    paragraphSpacingChanged: function (paragraphSpacing) {
      this.wxRenderer.setOptions({
        paragraphSpacing: paragraphSpacing
      });
      this.refresh()
      localStorage.setItem("__config_paragraph_spacing", paragraphSpacing);
    },
    imgCornerChanged: function (imgCorner) {
      this.wxRenderer.setOptions({
        imgCorner: imgCorner
      })
      this.refresh()
      localStorage.setItem("__config_img_corner", imgCorner)
    },
    imgShadowChanged: function (imgShadow) {
      this.wxRenderer.setOptions({
        imgShadow: imgShadow
      })
      this.refresh()
      localStorage.setItem("__config_img_shadow", imgShadow)
    },
    themeChanged: function (themeName) {
      let themeObject = this.styleThemes[themeName];
      this.wxRenderer.setOptions({
        theme: themeObject
      });
      this.refresh()
      localStorage.setItem("__config_theme", themeName);
    },
    justifyChanged: function (justifyAlign) {
      this.wxRenderer.setOptions({
        justifyAlign: justifyAlign ? 'justify' : 'left'
      })
      this.refresh()
      localStorage.setItem("__config_justify", justifyAlign);
    },
    showCodeLineNumChanged: function (showCodeLineNum) {
      this.wxRenderer.setOptions({
        showCodeLineNum: showCodeLineNum
      })
      this.refresh()
      localStorage.setItem("__config_show_code_line_num", showCodeLineNum)
    },
    autoInsertSpaceChanged: function (autoInsertSpace) {
      this.autoInsertSpace = autoInsertSpace;
      this.refresh()
      localStorage.setItem("__config_auto_insert_space", autoInsertSpace)
    },

    // 刷新右侧预览
    refresh: function () {
      this.output = this.renderWeChat(this.autoInsertSpace ? this.insertSpace(this.editor.getValue(0)) : this.editor.getValue(0))
    },
    // 将左侧编辑器内容保存到 LocalStorage
    saveEditorContent: function () {
      let content = this.editor.getValue(0);
      if (content) {
        localStorage.setItem("__editor_content", content);
      } else {
        localStorage.removeItem("__editor_content");
      }
    },
    removeEditorContent: function () {
      localStorage.removeItem("__editor_content");
    },
    copy: function () {
      let clipboardDiv = document.getElementById('output');
      clipboardDiv.focus();
      window.getSelection().removeAllRanges();
      let range = document.createRange();

      try {
        range.setStartBefore(clipboardDiv.firstChild);
        range.setEndAfter(clipboardDiv.lastChild);
        window.getSelection().addRange(range);
      } catch (err) {
        this.$message.error('请输入文字');
        return
      }

      try {
        if (document.execCommand('copy')) {
          this.$notify({
            title: '已复制到剪贴板',
            message: '可以去公众号粘贴了',
            type: 'success',
            duration: 2400
          });
          //复制成功后删除
          this.removeEditorContent()
        } else {
          this.$notify({
            title: '复制失败',
            message: '请全选后右键复制或使用Chrome浏览器',
            type: 'error',
            duration: 2400
          });
        }
      } catch (err) {
        this.$notify({
          title: '复制失败',
          message: '请全选后右键复制或使用Chrome浏览器',
          type: 'error',
          duration: 2400
        });
      }
    },
    openWindow: function (url) {
      window.open(url);
    },
    checkCache(objArray, localStorageKey) {
      //版本兼容，如果缓存的主题名不在现版本的可选项中，就删除
      if (!objArray.some((obj) => {
        return obj.value === localStorage.getItem(localStorageKey)
      })) {
        localStorage.removeItem(localStorageKey)
      }
    },
    insertSpace(str) {
      var p1 = /([A-Za-z0-9])([\u4e00-\u9fa5]+)/gi;
      var p2 = /([\u4e00-\u9fa5]+)([A-Za-z0-9])/gi;
      return str.replace(p1, "$1 $2").replace(p2, "$1 $2")
    },
    selectFile() {
      document.getElementById('selectMD').click()
    },
    onFileSelected(e) {
      let file = e.target.files[0];
      if (file) {
        let reader = new FileReader()
        reader.readAsText(e.target.files[0], 'UTF-8')
        let self = this
        reader.onload = function (e) {
          self.editor.setValue(e.target.result)
        }
      }
    }
  },
  updated: function () {
    this.$nextTick(function () {
      prettyPrint()
    })
  }
}
</script>

