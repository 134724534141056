import Vue from 'vue'
import App from './App.vue'
import './plugins/element-ui'
import './plugins/code-mirror'
import './assets/css/app.css'
import './assets/css/fonts.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
